/********************************
******ACCOUNT SIDEBAR************
********************************/

$(document).on("click", "#support-open", function() {
    var chatbotContainer = $('.mwai-chatbot-container');
    var openButton = chatbotContainer.find('.mwai-open-button');
    var closeButton = chatbotContainer.find('.mwai-close-button');
    var chatbotVisible = chatbotContainer.find('.mwai-chatbot').hasClass('mwai-transition-visible');
    if (openButton.length) {
      if (chatbotVisible && closeButton.length) {
        closeButton.trigger('click');
        setTimeout(function() {
          openButton.trigger('click');
        }, 500);
      } else {
        openButton.trigger('click');
      }
    }
});

// Remove params when leaving page
$(document).on("click", ".account-sidebar-wrapper a, .account-header a, .custom-blocks a", function(e) {
    e.preventDefault();
    // Creat new URL without params
    const targetUrl = new URL($(this).attr("href"), window.location.origin);
    const newUrl = targetUrl.origin + targetUrl.pathname;
    // Update the URL and navigate to the new page without params
    window.location.href = newUrl;
});


/********************************
******ACCOUNT QUOTES ******
********************************/

// function get_quotes() {
//     // ajax call to et quotes
//      jQuery('.open-quotes ul').html('');
//      jQuery('.closed-quotes ul').html('');
//     jQuery.ajax({
//         url: ajax_object.ajaxurl,
//         type: "post",
//         data: { action: "get_customer_quotes" },
//         success: function(data) {
//             if (data != 0) {
//             var quotes = data;
//             for (var i = 0; i < quotes.length; i++) {

//                     var quote = quotes[i];
//                     var quote_date = quotes[i].lead_received_date;
//                     var status = quotes[i].status;

//                     var date = new Date(quote_date);
//                     var month = ("0" + (date.getMonth() + 1)).slice(-2);
//                     var day = ("0" + date.getDate()).slice(-2);
//                     var year = date.getFullYear();
//                     // just show 24 as the year
//                     year = year.toString().slice(-2);
//                     var formatted_date = day + '/' + month + '/' + year;
//                     var industry = quote.industry;
//                     var optinurl = quote.optinurl;

//                     if (status != 'Sold') {
//                         var quote_html = '<li><div class="date"><p>' + formatted_date + '</p></div><div class="quote-name"><p><i class="fa-solid fa-clipboard-list" aria-hidden="true"></i> ' + industry + '</p></div><div class="cta"><a target="_blank" href="' + optinurl + '">Finish</a></div></li>';
//                         jQuery('.open-quotes ul').append(quote_html);
//                     } else {
//                         var quote_html = '<li><div class="date"><p>' + formatted_date + '</p></div><div class="quote-name"><p><i class="fa-solid fa-clipboard-list" aria-hidden="true"></i> ' + industry + '</p></div><div class="cta"><a target="_blank" href="' + optinurl + '">Leave Review</a></div></li>';
//                         jQuery('.closed-quotes ul').append(quote_html);
//                     }
                
//             }
//             }
//         }
//     });
// }


// Get Quotes on page load
jQuery(document).ready(function(){
    if (jQuery('.quotes-tracker').length > 0) {
    get_quotes();
    }
});

/********************************
******ACCOUNT QUESTIONNAIRE TEMPLATE******
********************************/

function update_points_total() {
    jQuery.ajax({
        url: ajax_object.ajaxurl,
        type: "post",
        data: { action: "get_user_data_on_login" },
        success: function(data) {
            if (data.error) {
                console.error('Error:', data.error);
            } else {
                // get customer_points
                var customer_points = data.customer_points;
                var customer_balance = customer_points / 1000;
                var level_name = data.level_name;
                var next_level_name = data.next_level_name;
                var points_to_next_level = data.points_to_next_level;
                if(customer_points > 5000){
                    var points_to_next_level_percentage = 100;
                } else {
                    var points_to_next_level_percentage = data.points_percentage;
                }
                jQuery('#points-total').text(customer_points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                jQuery('#balance-total, #wallet-total').text(`£${customer_balance.toFixed(2)}`);
                jQuery('#current-level').text(level_name);
                jQuery('.content-top p:eq(2)').text(' Points to eneter ' + next_level_name);
                jQuery('#remaining-points').text(points_to_next_level);
                jQuery('.points-progress-inner').css('width', points_to_next_level_percentage + '%');
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            //console.error('AJAX Error:', textStatus, errorThrown);
        }
    });
}

// Slick
jQuery('.account-questionnaire-wrapper .available-rewards-wrapper .available-rewards-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    autoplaySpeed: 6000,
    dots: true,
    arrows: false,
    infinite: false,
    responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
});

// Save Answers
var lastExecutionTime = 0;

jQuery(document).on("click", "#save-questionnaire", function(event){ 
    var currentTime = Date.now();
    var timeElapsed = (currentTime - lastExecutionTime) / 1000;

    if (timeElapsed < 60) {
        var remainingTime = Math.ceil(60 - timeElapsed);
        fireNotyf(`Slow down! Please wait <strong>${remainingTime} seconds</strong> before submitting again.`, 'error');
        return;
    }

    lastExecutionTime = currentTime;
    
    event.preventDefault();
    
    var $button = jQuery(this); // Reference the button
    var questionaireBlock = jQuery(this).closest('.questionnaire-block-bottom');
    var $questionnaire = jQuery(this).closest('.questionnaire-block-bottom'),
        answers = [];
    var allAnswered = true;

    // Disable the button to prevent multiple clicks
    $button.prop('disabled', true);

    $questionnaire.find('.question-block').each(function() {
        if(jQuery(this).attr("data-conditional") == "true"){
            var isAnswered = true;
        } else {
            var field = jQuery(this).data('field');
            var value = '';
            var isAnswered = false;
            
            // Multiple Choice Values
            var multipleChoice = jQuery(this).find('.multiple-choice').find('.active');
            if (multipleChoice.length) {
                if(multipleChoice.closest('question-block').attr("data-conditional") == "true") {
                    isAnswered = true;
                } else {
                    value = jQuery.trim(multipleChoice.text()),
                    valueLength = value.length;
                    if(valueLength > 0) {
                        isAnswered = true;
                    }
                }
            }
            // Select Values
            var select = jQuery(this).find('select');
            if (select.length) {
                value = select.val(),
                    valueLength = value.length;
                if(valueLength > 0) {
                    isAnswered = true;
                }
            }
            // Input Values
            var input = jQuery(this).find('input');
            if (input.length) {
                value = input.val(),
                valueLength = value.length;
                if(valueLength > 0) {
                    isAnswered = true;
                }
            }
    
            if (!isAnswered) {
                allAnswered = false;
            }
            
            answers.push({ [field]: value });
        }
    });

    if (allAnswered) {
        var industryQuestionaire = jQuery(questionaireBlock).parent().data("industry");
        // if industryQuestionaire has spaces, replace with underscore
        industryQuestionaire = industryQuestionaire.replace(/\s/g, '_');
        answers.push({ [industryQuestionaire]: 1 });
        // if (jQuery(questionaireBlock).prev().find('.icon').hasClass("active")) {

        // } else {
        //     var industryPoints = jQuery(questionaireBlock).parent().data("questionairepoints");
        //     answers.push({ customer_points : industryPoints });
        // }
        // convert to json
        answers = JSON.stringify(answers);
        // AJAX
        jQuery.ajax({
            url: ajax_object.ajaxurl,
            type: "post",
            data: {action: "questionnaire_submit", questionnaireAnswers: answers},
            success: function (data) {
                jQuery(questionaireBlock).removeClass("active");
                jQuery(questionaireBlock).parent().removeClass("active");
                jQuery(questionaireBlock).parent().removeAttr("style");
                jQuery(questionaireBlock).prev().find('.icon').removeClass("error").addClass("active");
                fireNotyf('Successfully submitted questionnaire!', 'success');
                update_points_total();
                // Re-enable the button after success
                $button.prop('disabled', false);
            }, 
            error : function (response) {
                jQuery(questionaireBlock).prev().find('.icon').addClass("error");
                // Re-enable the button after error
                $button.prop('disabled', false);
            }
        });
    } else {
        var industryQuestionaire = jQuery(questionaireBlock).parent().data("industry");
        answers.push({ [industryQuestionaire]: 0 });
        if (jQuery(questionaireBlock).prev().find('.icon').hasClass("active")) {

        } else {
            // var industryPoints = jQuery(questionaireBlock).parent().data("questionairepoints");
            // answers.push({ customer_points : industryPoints });
            jQuery(questionaireBlock).prev().find('.icon').addClass("error");
            fireNotyf('Please select answers for all questions visible before submitting.', 'error');
            // Re-enable the button if form is incomplete
            $button.prop('disabled', false);
        }
    }
});
 
jQuery(document).on("click", ".account-questionnaire-wrapper .available-rewards-wrapper .available-reward", function(){
    if(jQuery(this).hasClass("locked")){
        fireNotyf('<a href="/account/how-to-earn/">Earn more points</a> to level up and redeem Gift Cards.', 'custom');
    }
});

jQuery(document).on("click", ".questionnaire-tiles-wrapper .questionnaire-tile", function(){
    var selectedSection = jQuery(this).data("section");
    jQuery(".questionnaire-tile.active").removeClass("active").addClass("hidden");
    jQuery(".questionnaire-tile").addClass("hidden");
    jQuery(this).addClass("active").removeClass("hidden");
    jQuery(".questionnaire-container").addClass("hidden");
    jQuery(`.questionnaire-container[data-section='${selectedSection}']`).removeClass("hidden").addClass("active");
});


jQuery(document).on("click", ".multiple-choice ul li", function(){
    var questionaireBlock = jQuery(this).closest('.questionnaire-block');
    if(jQuery(this).parent().find(".active")){
        jQuery(this).parent().find(".active").removeClass("active");
        jQuery(this).addClass("active");
    } else {
        jQuery(this).addClass("active");
    }
    if(jQuery(this).attr("data-conditional-answer") == "true"){
        questionaireBlock.find('[data-conditional="1"]').attr("data-conditional", "true");
        questionaireBlock.find('[data-conditional="true"]').each(function(){
            jQuery(this).find("input").val("");
            jQuery(this).find("li.active").removeClass("active");
            jQuery(this).find("select").val("");
        });
    } else {
        questionaireBlock.find('[data-conditional="true"]').attr("data-conditional", "1");
    }
});

jQuery(window).on("load", function(){
    jQuery(".multiple-choice ul li.active").each(function(){
        var questionaireBlock = jQuery(this).closest('.questionnaire-block');
        if(jQuery(this).attr("data-conditional-answer") == "true"){
            questionaireBlock.find('[data-conditional="1"]').attr("data-conditional", "true");
            questionaireBlock.find('[data-conditional="true"]').each(function(){
                jQuery(this).find("input").val("");
                jQuery(this).find("li.active").removeClass("active");
                jQuery(this).find("select").val("");
            });
        }
    }); 
});

/********************************
******ACCOUNT GETTING STARTED TEMPLATE******
********************************/

// Start
var questionCount = jQuery(".question-block-wrapper:not(.hidden)").length,
questionProgress = 100 / questionCount;
questionProgressPer = Math.ceil(100 / questionCount);

jQuery(document).on("click", "#introduction-start", function(){
    jQuery(".introduction-wrapper:not(.hidden)").removeClass("active");
    jQuery(".question-block-wrapper[data-count='1']").addClass("active");
    setTimeout(function(){
        jQuery(".question-block-progress-inner").css("width", questionProgressPer+"%");
        jQuery(".question-block-progress-inner #progress").text(questionProgressPer+"%");
    }, 150);
});
// Question Count
var questionCount = 0;
jQuery(".question-block-wrapper:not(.hidden)").each(function(){
    questionCount++;
    jQuery(this).attr("data-count", questionCount);
});
// Go Back Questionnaire
jQuery(document).on("click", ".question-block-go-back #go-back", function(event){
    event.preventDefault();
    jQuery(this).closest(".question-block-wrapper.active").removeClass("active").prev().addClass("active");
    questionProgress = Math.ceil(questionProgress-questionProgressPer);
    if(questionProgress >= 97){
        questionProgress = 98;
    }
    setTimeout(function(){
        jQuery(".question-block-progress-inner").css("width", questionProgress+"%");
        jQuery(".question-block-progress-inner #progress").text(questionProgress+"%");
    }, 150);
});
jQuery(document).on("input", "#date-input", function () {
    let dateValue = jQuery(this).val().replace(/\D/g, '');
    if (dateValue.length > 8) {
        dateValue = dateValue.slice(0, 8);
    }
    if (dateValue.length >= 3 && dateValue.length <= 4) {
        dateValue = dateValue.slice(0, 2) + '/' + dateValue.slice(2);
    } else if (dateValue.length >= 5 && dateValue.length <= 8) {
        dateValue = dateValue.slice(0, 2) + '/' + dateValue.slice(2, 4) + '/' + dateValue.slice(4);
    }
    jQuery(this).val(dateValue);
});
jQuery(document).on("change", "#date-input", function () {
    var inputDate = jQuery(this).val();
    if (!/^\d{2}\/\d{2}\/\d{4}$/.test(inputDate)) {
        $(this).val('');
        fireNotyf("Please enter your Date of Birth in this format: DD/MM/YYYY", "error");
        return false;
    }
});
// Questionnaire
jQuery(document).on("click", "#question-next", function(event){
    event.preventDefault();
    if(jQuery(this).closest(".question_type").hasClass("last")){
        return;
    } else {
        jQuery(".question-block-go-back").show();
        var $questionnaire = jQuery(this).closest('.question-block-inner');
        var goNext = true,
            dobCheck = true;
        $questionnaire.find('.answers-wrapper').each(function() {
            // Multiple Choice Values
            var multipleChoice = $questionnaire.find('.multiple-choice');
            if (multipleChoice.length) {
                value = multipleChoice.find('.active').text();
                if(value == ""){
                    goNext = false;
                    return false; // Breaks out of the loop if a required field is empty
                }
            }
            // Select Values
            jQuery($questionnaire).find('select').each(function() {
                value = jQuery(this).val();
                if (jQuery('.question-block-wrapper.active #address-list').is(':hidden')) {
                    goNext = true;
                } else {
                    if (jQuery(this).is('#address-list')) {
                        var selectedOption = jQuery(this).find('option:selected');
                        if (selectedOption.attr('data-street')) {
                            goNext = true;
                        } else {
                            goNext = false;
                            return false;
                        }
                    } else {
                        if (jQuery(this).length && value == ""){
                            goNext = false;
                            return false;
                        }
                    }
                }
            });
            // Input Values
            jQuery($questionnaire).find('input').each(function() {
                if (jQuery(this).length) {
                    if(jQuery(this).is(":hidden")){ 
                        // Address manual
                        if(!jQuery(".address-lookup-wrapper").hasClass("hidden")){
                            return false;
                        }
                    } else {
                        if(jQuery(this).is("#address-lookup")){
                            if(jQuery(".question-block-wrapper #address-list").hasClass("empty")){
                                goNext = false;
                                return false;
                            }
                        } else if(jQuery(this).is("#date-input")){
                            value = jQuery(this).val();
                            if(value == ""){
                                goNext = false;
                                return false;
                            } else {
                                var inputDate = jQuery(this).val(),
                                dateParts = inputDate.split("/"),
                                selectedDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]),
                                today = new Date(),
                                minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()), // Minimum age 18
                                maxDate = new Date(today.getFullYear() - 110, today.getMonth(), today.getDate()); // Maximum age 110
                                if (selectedDate > minDate || selectedDate < maxDate) {
                                    $(this).val('');
                                    goNext = false;
                                    dobCheck = false;
                                    return false;
                                } 
                            }
                        } else {
                            value = jQuery(this).val();
                            if(value == ""){
                                if(jQuery(this).parent().hasClass("optional")){
                                    goNext = true;
                                } else {
                                    goNext = false;
                                    return false;
                                }
                            }
                        }
                    }
                }
            });
        });
        if(goNext){
            questionProgress = Math.ceil(questionProgress+questionProgressPer);
            if(questionProgress >= 97){
                questionProgress = 98;
            }
            jQuery(".question-block-wrapper.active").removeClass("active").nextAll(':not(.hidden)').first().addClass("active");
            setTimeout(function(){
                jQuery(".question-block-progress-inner").css("width", questionProgress+"%");
                jQuery(".question-block-progress-inner #progress").text(questionProgress+"%");
            }, 150);
        } else {
            if(dobCheck == false) {
                fireNotyf('You must be 18 years or older to register.', 'error'); 
            } else {
                fireNotyf('Please select an answer before continuing!', 'error');
            }
        }
    }
});
// Flat not House
jQuery(document).on("change", ".question-block-wrapper.active #flat_number", function(){
    if(jQuery(this).val() > 0){
        jQuery(".question-block-wrapper.active #house_number").parent().addClass("optional");
    } else {
        jQuery(".question-block-wrapper.active #house_number").parent().removeClass("optional");
    }
});
// Address Lookup
function get_rebel_addresses_account(postcode) {
    jQuery("#address-lookup-btn").addClass("active");
    jQuery.ajax({
        url: ajax_object.ajaxurl,
        type: "post",
        data: { action: "rebel_get_addresses", postcode: postcode },
        success: function(data) {
            jQuery("#address-lookup-btn").removeClass("active");
            jQuery(".question-block-wrapper #address-lookup").empty();
            data = data.toString().replace(/0+$/, '');
            try {
                var dataArray = JSON.parse(data);
                if (dataArray.message && dataArray.message.includes("No addresses found for postcode")) {
                    jQuery(".address-lookup-wrapper").addClass("hidden");
                    jQuery(".address-manual-wrapper").show();
                    jQuery(".question-block-wrapper.active #address-list, .question-block-wrapper.active #address-lookup").empty().val("");
                    fireNotyf("Invalid Postcode! Please enter manually.", "error");
                    return;
                } else {
                    if (Array.isArray(dataArray)) {
                        jQuery(".question-block-wrapper #address-list").empty().append('<option disabled selected value="">Select your address</option>');
                        dataArray.forEach(function(address) {
                            var option = jQuery('<option></option>');
                            option.text(address.fullAddress)
                            .attr('data-street', address.street)
                            .attr('data-posttown', address.posttown)
                            .attr('data-postcode', address.postcode)
                            .attr('data-number', address.number)
                            .attr('data-uprn', address.uprn)
                            .attr('data-mpan', address.mpan)
                            .attr('data-flat', address.flat)
                            .attr('data-buildingname', address.buildingname)
                            .attr('data-fullAddress', address.fullAddress);
                            jQuery(".question-block-wrapper #address-list").append(option).removeClass("empty");
                        });
                    } else {
                        console.error("Parsed data is not an array", dataArray);
                    }
                }
            } catch (e) {
                console.error("Error parsing JSON", e);
            }
        },
    });    
}
jQuery(document).on("change", ".question-block-wrapper #address-lookup", function(){
    get_rebel_addresses_account(jQuery(this).val());
});
jQuery(document).on("click", "#address-lookup-btn", function(){
    get_rebel_addresses_account(jQuery(".question-block-wrapper #address-lookup").val());
});
jQuery(document).on("click", ".address-lookup-wrapper #manual-address", function(){
    jQuery(".address-lookup-wrapper").addClass("hidden");
    jQuery(".address-manual-wrapper").show();
    jQuery(".question-block-wrapper.active #address-list, .question-block-wrapper.active #address-lookup").empty().val("");
    fireNotyf("Please enter your address manually.", "custom");
});
jQuery(window).on("load", function(){
    if(jQuery(".address-lookup-wrapper #address-lookup").length > 0){
        if(jQuery(".address-lookup-wrapper #address-lookup").val().length > 0){
            get_rebel_addresses_account(jQuery(".question-block-wrapper #address-lookup").val());
        }
    }
});
// Submit 
jQuery(document).on("click", "#question-last", function(){
    var answers = []; 
    var i = 1;
    jQuery('.question-block-wrapper').each(function() {
        if(i >= jQuery('.question-block-wrapper').length){ // Last Question
            jQuery('.question-block-wrapper.active').each(function() {
                var field = jQuery(this).data('field'),
                value = '';
                // Multiple Choice Values
                var multipleChoice = jQuery(this).find('.multiple-choice');
                if (multipleChoice.length) {
                    if(multipleChoice.find(".active").length > 0){
                        value = multipleChoice.find(".active").attr("data-answer");
                        if(value == ""){
                            fireNotyf('Please select an answer before continuing!', 'error');
                            return false;
                        } else {
                            answers.push({ [field]: value });
                            completeInitialQuestionnaire(answers);
                        }
                    }
                }
                // Select Values
                var select = jQuery(this).find('select'); 
                if (select.length) {
                    value = select.val();
                    if(value == ""){
                        fireNotyf('Please select an answer before continuing!', 'error');
                        return false;
                    } else {
                        answers.push({ [field]: value });
                        completeInitialQuestionnaire(answers);
                    }
                }
                // Input Values
                var input = jQuery(this).find('input');
                if (input.length) {
                    if(input.attr("type") != "hidden"){
                        value = input.val();
                        if(value == ""){
                            fireNotyf('Please select an answer before continuing!', 'error');
                            return false;
                        } else {
                            answers.push({ [field]: value });
                            completeInitialQuestionnaire(answers);
                        }
                    }
                }
            });
        } else {
            var field = jQuery(this).data('field'),
                value = '';
            if(field != "address"){
               // Multiple Choice Values
               var multipleChoice = jQuery(this).find('.multiple-choice');
               if (multipleChoice.length) {
                   value = multipleChoice.find('.active').attr("data-answer") || "";
                   if(value == ""){
                       fireNotyf('Please select an answer before continuing!', 'error');
                       return;
                   }
               }
               // Select Values
               var select = jQuery(this).find('select');
               if (select.length) {
                   value = select.val();
                   if(value == ""){
                       fireNotyf('Please select an answer before continuing!', 'error'); 
                       return;
                   }
               }
               // Input Values
               var input = jQuery(this).find('input');
               if (input.length) {
                   if(input.is("#date-input")){
                    value = input.val();
                    if (/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
                        const [day, month, year] = value.split('/');
                        value = `${year}-${month}-${day}`;
                    }
                   } else {
                       value = input.val();
                       if(value == ""){
                           fireNotyf('Please select an answer before continuing!', 'error');
                           return;
                       }
                   }
               }
               answers.push({ [field]: value });
            } else { // address
                if (jQuery('.address-lookup-wrapper').hasClass('hidden')) {
                    if(jQuery(".address-manual-wrapper #house_number").val()){
                        answers.push({ house_number: jQuery(".address-manual-wrapper #house_number").val() });
                    }
                    if(jQuery(".address-manual-wrapper #flat_number").val()){
                        answers.push({ flat_number: jQuery(".address-manual-wrapper #flat_number").val() });
                    }
                    if(jQuery(".address-manual-wrapper #building_name").val()){
                        answers.push({ building_name: jQuery(".address-manual-wrapper #building_name").val() });
                    }
                    answers.push({ street: jQuery(".address-manual-wrapper #street").val() });
                    answers.push({ town_city: jQuery(".address-manual-wrapper #town_city").val() });
                    answers.push({ postcode: jQuery(".address-manual-wrapper #postcode").val() });
                } else {
                    var select = jQuery(this).find('select');
                    if (select.length) {
                        value = select.val();
                        if(value == ""){
                            fireNotyf('Please select an answer before continuing!', 'error'); 
                            return;
                        } else {
                            var selectedOption = select.find("option:selected");
                            selectedOption.data('number') && answers.push({ house_number: selectedOption.data('number') });
                            selectedOption.data('street') && answers.push({ street: selectedOption.data('street') });
                            selectedOption.data('posttown') && answers.push({ town_city: selectedOption.data('posttown') });
                            selectedOption.data('postcode') && answers.push({ postcode: selectedOption.data('postcode') });
                            selectedOption.data('flat') && answers.push({ flat_number: selectedOption.data('flat') });
                            selectedOption.data('buildingname') && answers.push({ building_name: selectedOption.data('buildingname') });
                            selectedOption.data('fulladdress') && answers.push({ full_address: selectedOption.data('fulladdress') });
                        }
                    }
                }
            }
            i++;
        }
    });
 });

 function completeInitialQuestionnaire(answers){
    answers = answers;
    answers.push({ "customer_points" : 1000 }); 
    answers.push({ "initial_questionaire" : 1 });
    answers.push({ "ssid_signup" : jQuery("#ssid_signup").val() });
    // convert to json
    answers = JSON.stringify(answers);
    jQuery(".completed-section-wrapper").addClass("active");
    jQuery(".question-block-wrapper.active").removeClass("active");
    fireConfetti();
    setTimeout(function(){
        killConfetti();
    }, 1500);
    // AJAX
    jQuery.ajax({
        url: ajax_object.ajaxurl,
        cache: false,
        type: "post",
        data: {action: "questionnaire_submit", questionnaireAnswers: answers},
        success: function (data) {
            update_points_total();
        }, error : function (response) {

        }
    });
 } 

 /********************************
******ACCOUNT PROFILE TEMPLATE******
********************************/

/* Edit Sections */

jQuery(document).on("click", ".profile-settings ul li", function(){
    if(jQuery(this).attr("id") != "support-open") {
        var seletedSection = jQuery(this).attr("data-id");
        jQuery(`.account-profile-inner-top, .account-profile-inner-bottom`).hide();
        jQuery(`.profile-edit-section.active`).removeClass("active").hide();
        jQuery(`.profile-edit-section[data-id=${seletedSection}]`).addClass("active").fadeIn(500);
    }
});

jQuery(document).on("click", ".profile-edit-section #edit-disabled", function(){
    jQuery(this).parent().hide();
    jQuery(this).closest(".disabled-input").find("input:disabled").removeAttr("disabled").show();
    jQuery(this).closest(".disabled-input").find("select:disabled").removeAttr("disabled").show();
});

/* Add New Address/Vehicle Sections */

jQuery(document).on("click", ".profile-edit-section #add-address, .profile-edit-section #add-vehicle", function(){
    var sectionID = jQuery(this).attr("id");
    jQuery(`.profile-edit-section.active`).removeClass("active").hide();
    jQuery(`.profile-edit-section.${sectionID}`).addClass("active").fadeIn(500);
});

/* Save & Cancel */

jQuery(document).on("click", ".profile-edit-section-bottom #cancel", function(){
    location.reload();
});

jQuery(document).on("click", ".profile-edit-section-bottom #save", function(){
    // Check to see if this is for a new address / vehicle
    if(jQuery(this).closest(".profile-edit-section").hasClass("add-address") || jQuery(this).closest(".profile-edit-section").hasClass("add-vehicle")) {
        var answers = [],
            saveStatus = true;
        jQuery(".profile-edit-section.active input").each(function(){
            var inputValue = jQuery(this).val(),
                inputID = jQuery(this).attr("id");
            // Detect if any fields missing
            if(!saveStatus){
                return;
            }
            // Detect and change checkbox input
            if(jQuery(this).attr("type") == "checkbox"){
                if (jQuery(this).is(':checked')) {
                    inputValue = "yes";
                } else {
                    inputValue = "no";
                }
            }
            // Detect optional fields and if required fields are empty
            if(inputID != "address-line-2" && inputID != "address-region"){
                if(inputValue != ""){
                    answers.push({ [inputID]: inputValue });
                    saveStatus = true;
                } else {
                    saveStatus = false;
                }
            } else {
                saveStatus = true;
                answers.push({ [inputID]: inputValue });
            }
        });
        jQuery(".profile-edit-section.active .multiple-choice").each(function(){
            var inputValue = jQuery(this).find('.active').text(),
                inputID = jQuery(this).attr("data-id");
            if(inputValue != ""){
                answers.push({ [inputID]: inputValue });
            } else {
                saveStatus = false;
            }
        });
        // If all required fields are filled then run AJAX
        if(saveStatus){
            answers = JSON.stringify(answers);
            // AJAX 
            jQuery.ajax({
                url: ajax_object.ajaxurl,
                type: "post",
                data: {action: "questionnaire_submit", questionnaireAnswers: answers},
                success: function (data) {
                    update_points_total();
                    fireNotyf("Profile information successfully updated.", "success");
                    setTimeout(function(){
                        location.reload();
                    }, 250);
                }, error : function (response) {
                    
                }
            });
        } else {
            fireNotyf('Please fill all required fields before saving.', 'error');
        }
    } else {
        var answers = [],
            saveStatus = true;
        jQuery(".profile-edit-section.active input, .profile-edit-section.active select").each(function(){
            var inputValue = jQuery(this).val(),
                inputID = jQuery(this).attr("id");
            // Detect if any fields missing
            if(!saveStatus){
                return;
            }
            if(inputID != "address-line-2" && inputID != "address-region"){
                if(inputValue != ""){
                    answers.push({ [inputID]: inputValue });
                } else {
                    saveStatus = false;
                }
            } else {
                saveStatus = true;
                answers.push({ [inputID]: inputValue });
            }
        });
        // If all required fields are filled then run AJAX
        if(saveStatus){
            answers = JSON.stringify(answers);
            // AJAX
            jQuery.ajax({
                url: ajax_object.ajaxurl,
                type: "post",
                data: {action: "personal_details_submit", questionnaireAnswers: answers},
                success: function (data) {
                    update_points_total();
                    fireNotyf("Profile information successfully updated.", "success");
                }, error : function (response) {
                   
                }
            });
        } else {
            fireNotyf('Please fill all required fields before saving.', 'error');
        }
    }
});


/********************************
******ACCOUNT FORM TEMPLATE******
********************************/

function checkInputValues() {
    jQuery('.tml-wrapper input, .account-pass-content-wrapper input').each(function() {
        if (jQuery(this).val()) {
            jQuery(this).addClass('active'); 
        } else {
            jQuery(this).removeClass('active');
        }
    });
}

setTimeout(function() {
    checkInputValues();
}, 500);

jQuery(".tml-wrapper input, .account-pass-content-wrapper input").on("change input", function(){
    checkInputValues();
});

jQuery(document).on("click", ".tml-wrapper #toggle-pwd", function(){
    if(jQuery(this).prev().attr("type") == "password"){
        jQuery(this).prev().attr("type", "text");
    } else {
        jQuery(this).prev().attr("type", "password")
    }
});

var percentage = 0; 
function passwordStrength(n, m) { 
    if (n < 4) { 
        percentage = 0; 
        jQuery(".password-strength-bar").css("background", "#ed2d15"); 
        jQuery("#min-chars").removeClass("active");
    } else if (n < 6) { 
        percentage = 20; 
        jQuery(".password-strength-bar").css("background", "#fc6c19"); 
        jQuery("#min-chars").removeClass("active");
    } else if (n < 8) { 
        percentage = 40; 
        jQuery(".password-strength-bar").css("background", "#fcc719"); 
        jQuery("#min-chars").removeClass("active");
    } else { 
        percentage = 50; 
        jQuery("#min-chars").addClass("active");
    }  
    // Lowercase Words only 
    if ((m.match(/[a-z]/) != null)) { 
        percentage += 10; 
    }
    // Uppercase Words only 
    if ((m.match(/[A-Z]/) != null)) { 
        percentage += 10; 
        jQuery("#upper-chars").addClass("active");
    } else {
        jQuery("#upper-chars").removeClass("active");
    }
    // Digits only 
    if ((m.match(/0|1|2|3|4|5|6|7|8|9/) != null)) { 
        percentage += 10; 
        jQuery("#number-chars").addClass("active");
    } else {
        jQuery("#number-chars").removeClass("active");
    }
    // Special characters 
    if ((m.match(/\W/) != null) && (m.match(/\D/) != null)) { 
        percentage += 10; 
        jQuery("#special-chars").addClass("active");
    } else {
        jQuery("#special-chars").removeClass("active");
    }
    // Ensure high %
    if(percentage >= 90){
        percentage = 100;
    }
    if(percentage >= 80){
        jQuery(".password-strength-bar").css("background", "#9aed34"); 
    }
    jQuery(".password-strength-bar").css("width", percentage + "%"); 
} 

jQuery(document).on("keyup", "#confirm-password", function(){
    var m = jQuery(this).val(),
        n = m.length; 
    var c = jQuery('#password').val();
    if(m == c) {
        passwordStrength(n, m); 
    }
    if(m.length < 1) {
        jQuery(".password-strength-bar").removeAttr("style");
    } 
});   

jQuery(document).on("click", ".account-pass-content-wrapper #submit-pass", function(){
    jQuery(".account-pass-content-wrapper form input").each(function(){
        var valueLength = jQuery(this).val().length;
        if(valueLength > 0){
            jQuery(".account-pass-content-wrapper #submit-pass, .account-pass-content-wrapper .account-progress .step2").addClass("active");
        } else {
            jQuery(".account-pass-content-wrapper #submit-pass, .account-pass-content-wrapper .account-progress .step2").removeClass("active");
        }
    });
}); 

/********************************
******COPY REFERRAL FUNCTIONS******
********************************/

function copyToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);       
    textArea.select();
    try {
        var successful = document.execCommand('copy');
        fireNotyf('Successfully copied to clipboard!', 'success');
        jQuery('.copy-code-inner').addClass('highlight');
        setTimeout(function(){
            jQuery('.copy-code-inner').removeClass('highlight');
        }, 500);
    } catch (err) {
        
    }    
    document.body.removeChild(textArea);
 }

jQuery(document).on("click", ".copy-code-inner #copy-code", function(){
    var clipboardText = "";
    clipboardText = $('.copy-code-inner #referral-url').val(); 
    copyToClipboard(clipboardText);
});

/********************************
******ACCOUNT DASHBOARD TEMPLATE******
********************************/
jQuery(window).on("load resize", function(){
    if(jQuery(window).outerWidth() < 992){
        if(!jQuery(".featured-rewards-container .featured-rewards").hasClass("slick-slider")){
            jQuery('.featured-rewards-container .featured-rewards').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 6000,
                arrows: false,
                dots: true,
                infinite: true,
                draggable: false
            });
        }
    }
});

jQuery(document).on("click", ".questionnaire-block[data-industry='leave a review_questionaire']", function(event){
    console.log("review status");
    jQuery.ajax({
        url: ajax_object.ajaxurl,
        type: "post",
        data: {action: "set_review_status"},
        success: function (response) {
            event.preventDefault();
            if(response.message.includes("pending approval")){
                jQuery(".questionnaire-block[data-industry='leave a review_questionaire'] .icon").addClass("amber");   
            }
            if(response.status == "error" || response.status == "custom" || response.status == "success"){
                fireNotyf(`${response.message}`, `${response.status}`);
            } else {
                return;
            }
        }
    });
});

jQuery(window).on("load", function(){
    if(jQuery('a[data-review-status="2"]').length){
        jQuery('a[data-review-status="1"]').removeAttr("href").removeAttr("target");
    }
}); 

/********************************
******ACCOUNT REWARDS REALM TEMPLATE******
********************************/

jQuery(document).on("click", "#show-all-available-rewards", function(){
    $('.available-rewards-inner .content.available-rewards-slider').slick('unslick').addClass("grid");
    jQuery(this).hide();
});

jQuery('.account-content-wrapper .featured-brands-inner .content.rewards-slider').slick({
    slidesToShow: 7,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: false,
    arrows: false,
    infinite: true,
    responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        }
      ]
});

jQuery('.rewards-realm-wrapper .available-rewards-wrapper:not(.active) .available-rewards-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    autoplaySpeed: 6000,
    dots: true,
    arrows: false,
    infinite: false,
    responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
}); 

jQuery('.account-content-wrapper .featured-brands-inner .content.rewards-slider, .account-content-wrapper .available-rewards-inner .content.available-rewards-slider').addClass("active"); 


// Load Reward Product
jQuery(document).on('click', '.featured-brands-wrapper .featured-reward', function(){
    jQuery('.rewards-realm-wrapper');
    var brand = jQuery(this).attr("data-brand");
    // change brand dropdown
    jQuery('#brand-filter').val(brand).change();
    // scroll down to rewards
});

// Load Gift Card Product
jQuery(document).on("click", ".rewards-realm-wrapper .available-rewards-wrapper .available-reward", function(){
    if(jQuery(this).hasClass("locked")){
        fireNotyf('<a href="/account/how-to-earn/">Earn more points</a> to level up and redeem Gift Cards.', 'custom'); 
    } else {
        // Load Gift Card Product
        const rewardID = jQuery(this).data("id");
        jQuery('.rewards-realm-wrapper');
        jQuery(this).toggleClass("active").siblings().removeClass("active");
        // FETCH DATA FROM PRODUCT ID
        jQuery.ajax({
            url: ajax_object.ajaxurl,
            type: "post",
            data: {action: "get_benefit_by_id", rewardID: rewardID},
            success: function(data) {
                var giftdata = JSON.parse(data);
                if(giftdata.status == "error"){
                    fireNotyf("Invalid Reward ID, please try again later or choose a different reward to redeem.", "error");
                    return;
                } else {
                    // Access the data directly since it's not wrapped in another object
                    var productImg = giftdata.data.pictureUrl;
                    var productTitle = giftdata.data.name;
                    var productPrice = giftdata.data.denomination;
                    var productDesc = giftdata.data.description;
                    var productTcs = giftdata.data.terms;
                    var productCost = giftdata.data.price;
                    // Update Product Viewer Data
                    jQuery('.product-viewer-image img').attr("src", productImg);
                    jQuery('.product-viewer-price #productPrice').html(`£${productPrice}`);
                    jQuery('.product-viewer-title #productTitle').html(productTitle);
                    jQuery('.product-viewer-description p').text(productDesc);
                    var formattedTcs = productTcs.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
                    // Set the formatted text to the target element
                    jQuery(".product-viewer-tcs-content p").html(formattedTcs);
                    // Show Product Viewer
                    jQuery('.rewards-realm-wrapper .account-content-wrapper').hide();
                    jQuery('.rewards-realm-wrapper .product-viewer-wrapper').addClass('active').attr("data-product-id", rewardID);  
                    // Scroll to top
                    jQuery('html, body').animate({
                        scrollTop: jQuery('.rewards-realm-wrapper').offset().top
                    }, 500);
                }
            }, 
            error: function (response) {
                console.error("Error fetching reward data:", response);
            }
        });
    }
});

// ON CLICK OF #redeem-gift-card AJAX CALL TO REDEEM GIFT CARD
jQuery(document).on('click', '#redeem-gift-card', function(){
    const urlParams = new URLSearchParams(window.location.search);
    let rewardID;
    // Check if productID exists in the URL parameters
    const productID = urlParams.get('productID');
    if (productID) {
        rewardID = productID.split('?')[0];
    } else {
        // If no productID, default to data attribute value
        rewardID = $('.product-viewer-wrapper.active').attr('data-product-id');
    }
    jQuery.ajax({
        url: ajax_object.ajaxurl,
        type: "post",
        data: {action: "purchase_gift_card", rewardID: rewardID},
        success: function (data) {
            if(data.status = "error"){
                jQuery(".product-viewer-redeem").addClass("active");
                jQuery(".product-viewer-redeem #status").addClass("error").text(data.message);
                return;
            } else {
                jQuery(".product-viewer-redeem").addClass("active");
                jQuery(".product-viewer-redeem #status").addClass("success").text(data.message);
                fireNotyf("Please check your inbox, your reward will be emailed directly to you.", "success");
            }
        },
        error: function (response) {
            console.error("Error fetching reward data:", response);
        }

    });
});

jQuery(window).on("load", function(){
    // Check for params
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('filterGiftCard')) {
        if (urlParams.has('filterBrand')) {
            urlParams.delete('filterBrand');
            const newUrl = window.location.pathname + '?' + urlParams.toString();
            window.history.replaceState(null, '', newUrl);
        }
        if(urlParams.has('productID')) {
            // Load Gift Card Product
            const rewardID = urlParams.getAll('productID')[0].split('?')[0];
            jQuery('.rewards-realm-wrapper');
            jQuery(this).toggleClass("active").siblings().removeClass("active");
            // FETCH DATA FROM PRODUCT ID
            jQuery.ajax({
                url: ajax_object.ajaxurl,
                type: "post",
                data: {action: "get_benefit_by_id", rewardID: rewardID},
                success: function(data) {
                    var giftdata = JSON.parse(data);
                    if(giftdata.status == "error"){
                        fireNotyf("Invalid Reward ID, please try again later or choose a different reward to redeem.", "error");
                        const newUrl = window.location.origin + window.location.pathname + '?filterGiftCard=true';
                        window.location.replace(newUrl);
                    } else {
                        // Access the data directly since it's not wrapped in another object
                        var productImg = giftdata.data.pictureUrl;
                        var productTitle = giftdata.data.name;
                        var productPrice = giftdata.data.denomination;
                        var productDesc = giftdata.data.description;
                        var productTcs = giftdata.data.terms;
                        var productCost = giftdata.data.price;
                        // Update Product Viewer Data
                        jQuery('.product-viewer-image img').attr("src", productImg);
                        jQuery('.product-viewer-price #productPrice').html(`£${productPrice}`);
                        jQuery('.product-viewer-title #productTitle').html(productTitle);
                        jQuery('.product-viewer-description p').text(productDesc);
                        var formattedTcs = productTcs.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
                        // Set the formatted text to the target element
                        jQuery(".product-viewer-tcs-content p").html(formattedTcs);
                        // Show Product Viewer
                        jQuery('.rewards-realm-wrapper .account-content-wrapper').hide();
                        jQuery('.rewards-realm-wrapper .product-viewer-wrapper').addClass('active');  
                        // Scroll to top
                        jQuery('html, body').animate({
                            scrollTop: jQuery('.rewards-realm-wrapper').offset().top
                        }, 500);
                    }
                }, 
                error: function (response) {
                    console.error("Error fetching reward data:", response);
                }
            });
        }
    }
    if (urlParams.has('filterBrand')) {
        const selectedBrand = urlParams.getAll('filterBrand')[0].split('?')[0];
        filterByBrand(selectedBrand);
    }
    // Filter Brand Select
    jQuery(document).on("change", "#brand-filter", function(){
        var selectedBrand = jQuery(this).val().toLowerCase();
        filterByBrand(selectedBrand);
    });
    // Filter Search Input
    let debounceTimer;
    jQuery(document).on("input", "#search-filter", function () {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(function () {
            filterBySearch(); // Call the function after 1 second of no input
        }, 1000);
    });    
    // View all brands
    jQuery(document).on("click", ".brands-view-all #view-all-brands", function(){
        jQuery(this).hide();
        jQuery(".brands-list-inner .brands-list").addClass("active");
    });
    // Discount Click
    jQuery(document).on("click", ".brands-list .brand-block, .rewards-realm-wrapper .featured-reward", function(){
        var selectedBrand = jQuery(this).data("brand").toLowerCase();
        jQuery("#brand-filter").val(jQuery(this).data("brand"));
        filterByBrand(selectedBrand);
    });
    // Reveal Code
    jQuery(document).on("click", ".reward-block-code #reveal-code", function(){
        $rewardBlock = jQuery(this).closest(".reward-block");
        $rewardBlock.find("#reveal-code").addClass("active");
        var rewardID = jQuery(this).closest(".reward-block").attr("data-id");
        // AJAX
        jQuery.ajax({
            url: ajax_object.ajaxurl,
            type: "post",
            data: { action: "purchase_discount", rewardID: rewardID }, 
            success: function(data) {
                var dataArray = JSON.parse(data);
                $rewardBlock.find("#reveal-code").removeClass("active").addClass("used");
                window.open(dataArray.data.clickThroughUrl, '_blank');
            }
        });
    });
    // Show T&C's
    jQuery(document).on("click", ".product-viewer-tcs #showTcs", function(){
        jQuery(this).hide();
        jQuery(".product-viewer-tcs-content").show();
    });
    // Show Redeem Section
    function showRedeem(){
        jQuery(".rewards-realm-wrapper .account-content-wrapper > div:not(.brands-list-wrapper)").hide();
        jQuery(".brands-list-wrapper .brand-rewards-wrapper").show();
    }
    // Filter by Search
    function filterBySearch(){
        var selectedSearch = $("#search-filter").val().toLowerCase();
        jQuery("#brand-filter").val("all");
        if(jQuery(".brand-rewards-inner").length > 0){
            // AJAX
            jQuery.ajax({
                url: ajax_object.ajaxurl,
                type: "post",
                data: { action: "filter_rewards_search", selectedSearch: selectedSearch }, 
                success: function(data) {
                    if(data.message == "No data found"){
                        fireNotyf("No results, try selecting a brand or searching for something else.", "error");
                        jQuery(".brand-rewards-wrapper").hide();
                        jQuery(".brand-rewards-wrapper.active").removeClass("active");
                        jQuery(`.brand-rewards-inner .brand-logo`).show();
                        jQuery(`.brand-rewards-inner .rewards-list`).empty();
                    } else {
                        if(selectedSearch == ""){
                            jQuery(".brand-rewards-wrapper").hide();
                            jQuery(".brand-rewards-wrapper.active").removeClass("active");
                            jQuery(`.brand-rewards-inner .brand-logo`).show();
                            jQuery(`.brand-rewards-inner .rewards-list`).empty();
                        } else {
                            jQuery(".brand-rewards-wrapper").show();
                            jQuery(".brand-rewards-wrapper").addClass("active");
                            jQuery(`.brand-rewards-inner .brand-logo`).hide();
                            jQuery(`.brand-rewards-inner .rewards-list`).empty();
                            // Loop & Append results
                            for (var i = 0; i < data.length; i++) {
                                var productImg = data[i].pictureUrl,
                                productTitle = data[i].name,
                                productID = data[i].deal_id,
                                productBrand = data[i].brand.toLowerCase();
                                jQuery(`.brand-rewards-inner .rewards-list`).append(`
                                    <div class="reward-block" data-id="${productID}" data-brand="${productBrand}">
                                        <div class="reward-block-left">
                                            <div class="reward-block-image">
                                                <img src="${productImg}">
                                            </div>
                                            <div class="reward-block-title">
                                                <p>${productTitle}</p>
                                            </div>
                                        </div>
                                        <div class="reward-block-right">
                                            <div class="reward-block-code">
                                                <button id="reveal-code"><span>Use Now</span><i class="fas fa-spinner fa-spin"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                `);
                            }
                        }
                    }
                }
            });
        }
    }
    // Filter By Brand
    function filterByBrand(selectedBrand){
        showRedeem();
        jQuery("#search-filter").val("");
        jQuery(".brands-list-wrapper .brand-rewards-wrapper").removeClass("active");
        jQuery("html, body").animate({
            scrollTop: jQuery(".hero-block").offset().top
        }, 500);
        if(jQuery(".brand-rewards-inner").length > 0){ 
            // AJAX
            jQuery.ajax({
                url: ajax_object.ajaxurl,
                type: "post",
                data: { action: "filter_rewards_brand", selectedBrand: selectedBrand }, 
                success: function(data) {
                    jQuery(`.brand-rewards-inner .rewards-list`).empty();
                    if(data.message == "No data found"){
                        fireNotyf("No deals found for this brand, please check back later.", "error");
                        jQuery(".rewards-realm-wrapper .account-content-wrapper > div").show();
                        jQuery(".brands-list-wrapper .brand-rewards-wrapper").hide();
                        return;
                    } else {
                        jQuery(".brands-list-wrapper .brand-rewards-wrapper").addClass("active");
                    }
                    if(selectedBrand != 'all'){
                        for (var i = 0; i < data.length; i++) {
                            var productImg = data[i].pictureUrl,
                            productTitle = data[i].name,
                            productID = data[i].deal_id,
                            productCode = data[i].code,
                            productBrand = data[i].brand.toLowerCase();
                            jQuery(`.brand-rewards-inner .brand-logo`).find("img").attr("src", productImg);
                            jQuery(`.brand-rewards-inner .rewards-list`).append(`
                                <div class="reward-block" data-id="${productID}" data-brand="${productBrand}">
                                    <div class="reward-block-left">
                                        <div class="reward-block-image">
                                            <img src="${productImg}">
                                        </div>
                                        <div class="reward-block-title">
                                            <p>${productTitle}</p>
                                        </div>
                                    </div>
                                    <div class="reward-block-right">
                                        <div class="reward-block-code">
                                            <button id="reveal-code"><span>Use Now</span><i class="fas fa-spinner fa-spin"></i></button>
                                        </div>
                                    </div>
                                </div>
                            `);
                        }
                    } else {
                        // Rewards loading error
                        console.error("Erorr loading data.");
                    }
                }
            });
        }
    }
});

////////// D2D TEMPLATES //////////


/* Profile */
jQuery(document).on("click", ".profile-details-wrapper #save-profile", function(){
    var answers = [],
    saveStatus = true;
    jQuery(".profile-details-inner input").each(function(){
        var inputValue = jQuery(this).val(),
            inputID = jQuery(this).attr("id");
        if(inputValue != ""){
            answers.push({ [inputID]: inputValue });
        } else {
            fireNotyf("Please fill all fields before continuing.", "error");
            saveStatus = false;
        }
    });
    // If all required fields are filled then run AJAX
    if(saveStatus){
        answers = JSON.stringify(answers);
        // AJAX
        jQuery.ajax({
            url: ajax_object.ajaxurl,
            type: "post",
            data: {action: "personal_details_submit", questionnaireAnswers: answers},
            success: function (data) {
                update_points_total();
                fireNotyf("Profile information successfully updated.", "success");
            }, error : function (response) {
                
            }
        });
    } else {
        fireNotyf('Please fill all required fields before saving.', 'error');
    }
});

/* Leaderboards */
jQuery(document).on("click", ".reports-table-wrapper ul li", function(){
    var selectedType = jQuery(this).data("type");
    jQuery(".reports-table-wrapper ul li.active").removeClass("active");
    jQuery(this).addClass("active");
    // Tables
    jQuery(".reports-table-wrapper .table-wrapper.active").removeClass("active");
    jQuery(`.reports-table-wrapper .table-wrapper[data-type="${selectedType}"]`).addClass("active");
});

function fetchLeaderboardData() {
    let selectedFilter = jQuery("#leaderboard-filter").val(); // Get selected filter
    // Show Loader
    jQuery(".stats-box-wrapper.active").each(function(){
        jQuery(this).removeClass("active");
    });
    jQuery.ajax({
        url: ajax_object.ajaxurl,
        type: "POST",
        data: { 
            action: "get_leaderboard_top_stats",
            dateFilter: selectedFilter 
        }, 
        success: function(response) {
            if (response.success) {
                let leads = response.data,
                    notSoldCount = 0,
                    SoldCount = 0;
                console.log(leads);
                leads.forEach(function(lead) {
                    let status = lead.status; 
                    // Count 'No Answer' or any other non-sold status
                    if (status !== null) {
                        status = lead.status.toLowerCase(); 
                        if (status === "no answer" || status === "not sold") {
                            notSoldCount++;
                        } else if (status === "sold") {
                            SoldCount++;
                        }   
                    }
                });
                // Update top stats
                jQuery(".stats-box-wrapper").each(function(){
                    jQuery(this).addClass("active");
                    jQuery(this).find("#quotes-opened span").text(leads.length);
                    jQuery(this).find("#quotes-lost span").text(notSoldCount);
                    jQuery(this).find("#quotes-won span").text(SoldCount);
                });                
            } else {
                console.error("Error:", response.data);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.error("AJAX Error:", textStatus, errorThrown);
        }
    });
}

jQuery(window).on("load", function(){
    if(jQuery(".d2d-leaderboard-wrapper").length){
        fetchLeaderboardData();
    }
});

jQuery("#leaderboard-filter").on("change", function() {
    fetchLeaderboardData();
});

/* Resources */

$(document).on("click", ".resource-map-wrapper .resource-block-wrapper", function() {
    var selectedIdentifier = $(this).data("identifier");
    if(selectedIdentifier !== "ask-roo"){
        jQuery(".page-template-pl-d2d-resources .content-area").addClass("active");
        jQuery(".d2d-resources-wrapper .resource-content-wrapper.active").removeClass("active");
        jQuery(`.d2d-resources-wrapper .resource-content-wrapper[data-identifier="${selectedIdentifier}"]`).addClass("active");
    }
});

$(document).on("click", ".resource-block-wrapper[data-identifier='ask-roo']", function() {
    var chatbotContainer = $('.mwai-chatbot-container');
    var openButton = chatbotContainer.find('.mwai-open-button');
    var closeButton = chatbotContainer.find('.mwai-close-button');
    var chatbotVisible = chatbotContainer.find('.mwai-chatbot').hasClass('mwai-transition-visible');
    if (openButton.length) {
      if (chatbotVisible && closeButton.length) {
        closeButton.trigger('click');
        setTimeout(function() {
          openButton.trigger('click');
        }, 500);
      } else {
        openButton.trigger('click');
      }
    }
});